import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-coupon-upload',
  templateUrl: './coupon-upload.component.html',
  styleUrls: ['./coupon-upload.component.css']
})
export class CouponUploadComponent implements OnInit {

  fileToUpload: File = null;
  @ViewChild('couponId') couponId:ElementRef;
  @ViewChild('couponType') couponType:ElementRef;
  @ViewChild('merchant') merchant:ElementRef;
  @ViewChild('amount') amount:ElementRef;
  @ViewChild('expiryDate') expiryDate:ElementRef;
  @ViewChild('usage') usage:ElementRef;

  constructor(public rest:RestService) { }

  ngOnInit() {
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);

    let fileName = files.item(0).name;

    //let tmpfileName:string = files.item(0).name;
    //const re = /(?:\.([^.]+))?$/;
    //let ext = re.exec(tmpfileName)[1];
    //const fileName = this.couponId.nativeElement.value + '.' + ext;

    /*
    Object.defineProperty(this.fileToUpload, 'name', {
      writable: true,
      value: fileName
    });
    */

    console.log(this.couponId.nativeElement.value);
    console.log(this.couponType.nativeElement.value);
    console.log(this.merchant.nativeElement.value);
    console.log(this.amount.nativeElement.value);
    console.log(this.expiryDate.nativeElement.value);
    console.log(this.usage.nativeElement.value);
    console.log(fileName);

    var postBody =
    `{
      "couponId": "` + this.couponId.nativeElement.value + `",
      "couponType": "` + this.couponType.nativeElement.value + `" ,
      "merchant": "` + this.merchant.nativeElement.value + `" ,
      "amount": "` + this.amount.nativeElement.value + `" ,
      "uploadDate": "2020-07-18",
      "expiryDate": "` + this.expiryDate.nativeElement.value + `" ,
      "couponUsageType": "` + this.usage.nativeElement.value + `" ,
      "s3Url": "https://siri-testbucket-1.s3.amazonaws.com/` + fileName + `"
      }`;
      console.log(postBody);
      this.uploadFileToActivity(fileName, postBody);
  }

  uploadFileToActivity(fileName: string, postBody: string) {
    this.rest.postFile(this.fileToUpload).subscribe(data => {
      // do something, if upload success
      console.log('Successful upload' + fileName);
      }, error => {
        console.log('Error ' + JSON.stringify(error) + ' : ' + fileName);
        this.uploadFileDetails(fileName, postBody);
      });
  }

  uploadFileDetails(fileName: string, postBody: string) {
    this.rest.updateDetails(fileName, postBody).subscribe((data: {}) => {
      console.log('update details response data ' + JSON.stringify(data));
      //this.selectedCoupon = data;
    });
  }

}
