import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CouponComponent } from './coupon/coupon.component';
import { CouponDetailComponent } from './coupon-detail/coupon-detail.component';
import { CouponUploadComponent } from './coupon-upload/coupon-upload.component';
import { LoginComponent } from './login/login.component';

// ROUTES
const appRoutes: Routes = [
    { path: '', redirectTo: 'coupon', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },    
    { path: 'coupon', component: CouponComponent },
    { path: 'coupon-detail/:id', component: CouponDetailComponent },
    { path: 'coupon-upload', component: CouponUploadComponent }
];

@NgModule({
  imports: [
    RouterModule.forRoot(
      appRoutes,
      { enableTracing: true })
  ],
  exports: [
      RouterModule
  ]
})
export class AppRoutingModule { }
