import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';

const getCouponsEndpoint = 'https://s7utk4u7b1.execute-api.us-east-1.amazonaws.com/DevTest/get-coupon-list';
const getCouponEndpoint = 'https://s7utk4u7b1.execute-api.us-east-1.amazonaws.com/DevTest/get-coupon';
const savetoDBEndpoint = 'https://s7utk4u7b1.execute-api.us-east-1.amazonaws.com/DevTest/get-and-post-file';
const updateCouponEndpoint = 'https://s7utk4u7b1.execute-api.us-east-1.amazonaws.com/DevTest/updatestatus';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json'
  })
};

@Injectable({
  providedIn: 'root'
})
export class RestService {

  constructor(private http: HttpClient) { }

  private extractData(res: Response) {
    let body = res;
    console.log('extractData: ' + JSON.stringify(body))
    return body || { };
  }

  private extractData1(res: Response) {
    let body = res;
    console.log('extractData: ' + res)
    return body.url || { };
  }

  public getCoupons(): Observable<any> {
    return this.http.get(getCouponsEndpoint).pipe(
      map(this.extractData1));
  }

  public updateCoupon(couponId: string): Observable<any> {
    var postBody =
    `{
      "couponId" : "` + couponId +  `"
      }
    `;
    return this.http.post(updateCouponEndpoint, postBody).pipe(
      map(this.extractData1));
  }

  public getCoupon(id): Observable<any> {
    var postBody =
    `{
      "couponId": "` + id + `"
    }`;

    return this.http.post(getCouponEndpoint, postBody).pipe(
      map(this.extractData));
  }

  public updateDetails(fileName, postBody): Observable<any> {
    var postBody1 =
    `{
      "couponId": "redbox-3",
      "couponType": "Digital",
      "merchant": "Redbox",
      "amount": "$1.25 off your rental",
      "uploadDate": "2020-01-10",
      "expiryDate": "2020-01-12",
      "s3Url": "https://siri-testbucket-1.s3.amazonaws.com/tgif2.png"
      }`;

    return this.http.post(savetoDBEndpoint, postBody).pipe(
      map(this.extractData));
  }

  public postFile(fileToUpload: File): Observable<boolean> {
      //const endpoint = 'https://s7utk4u7b1.execute-api.us-east-1.amazonaws.com/DevTest/get-and-post-file';
      const endpoint = 'https://infinite-peak-44125.herokuapp.com/storage/uploadFile';

      const formData: FormData = new FormData();
      formData.append('file', fileToUpload);
      console.log('File Details: ' + JSON.stringify(fileToUpload.name));
      return this.http
        .post(endpoint, formData, { headers: null }) //yourHeadersConfig
        .pipe(map(() => { return true; }));
        //.catchError((e) => this.handleError(e));
  }

  private handleError<T> (operation = 'operation', result?: T) {
    return (error: any): Observable<T> => {

      // TODO: send the error to remote logging infrastructure
      console.error(error); // log to console instead

      // TODO: better job of transforming error for user consumption
      console.log(`${operation} failed: ${error.message}`);

      // Let the app keep running by returning an empty result.
      return of(result as T);
    };
  }

}
