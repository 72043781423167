import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { Coupon } from '../coupon';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-coupon',
  templateUrl: './coupon.component.html',
  styleUrls: ['./coupon.component.css']
})
export class CouponComponent implements OnInit {
  coupons:any = [];
  selectedCoupon: Coupon;

  constructor(public rest:RestService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.getCoupons();
  }

  getCoupons() {
    this.coupons = [];
    this.rest.getCoupons().subscribe((data: {}) => {
      console.log(data);
      this.coupons = data;
    });
  }

  onSelect(coupon: Coupon): void {
    this.selectedCoupon = coupon;
    this.router.navigate(['/coupon-detail', coupon.couponId]);
  }

  usedCoupon(coupon: Coupon): void {
    this.selectedCoupon = coupon;
    this.rest.updateCoupon(coupon.couponId).subscribe((data: {}) => {
      console.log(data);
      this.coupons = data;
    });
    this.router.navigate(['/coupon']);
  }
}
