import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';
import { Coupon } from '../coupon';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-coupon-detail',
  templateUrl: './coupon-detail.component.html',
  styleUrls: ['./coupon-detail.component.css']
})
export class CouponDetailComponent implements OnInit {
  selectedCoupon: any;
  selectedId: string;

  constructor(public rest:RestService, private route: ActivatedRoute, private router: Router) { }

  ngOnInit() {
    this.selectedId = this.route.snapshot.paramMap.get('id');

    this.getCoupon(this.selectedId);
  }

  getCoupon(id:string) {
    //this.selectedCoupon = new Coupon();
    console.log('id: ' + id);
    this.rest.getCoupon(id).subscribe((data: {}) => {
      console.log('coupon detail response data ' + JSON.stringify(data));
      //assign each one individually
      this.selectedCoupon = data;
    });
  }

}
